.attachment-list {
	@include clearfix;
	width: 100%;
}

.attachment {
	position: relative;
	display: inline-block;
	float: left;
	padding: 0.618rem 0.618rem;
	margin-right: 0.618rem;
	margin-bottom: 1rem;

	background-color: transparentize($theme-three, 0.75);
	border: $rule-stack;
	border-color: $theme-three;
	border-radius: $border-radius-medium;

	> span {
		display: inline-block;
		vertical-align: middle;

		> img {
			height: 1.5rem;
			width: auto;
			margin-left: 0.5rem;
		}
	}
}