@mixin material-shadow ($material-height: 5, $shadow-color: black, $inset: null) {
	$material-height: $material-height / 2;

	$drop-distance: 1.2;
	$ambeint-distance: 0.5;

	$angle-ratio-x: -0.4;
	$angle-ratio-y: 1.8;

	$drop-x: $angle-ratio-x * $material-height * $drop-distance + px;
	$drop-y: $angle-ratio-y * $material-height * $drop-distance + px;

	$ambient-x: $angle-ratio-x * $material-height * $ambeint-distance + px;
	$ambient-y: $angle-ratio-y * $material-height * $ambeint-distance + px;

	$drop-blur: 3 + $material-height * 2.5 + px;
	$ambient-blur: 0 + $material-height * 2 + px;

	$drop-color: transparentize($shadow-color, min(65 + $material-height, 100) / 100);
	$ambient-color: transparentize($shadow-color, min(80 + $material-height * 2, 100) / 100);

	box-shadow: 
		$drop-x $drop-y $drop-blur $drop-color $inset,
		$ambient-x $ambient-y $ambient-blur $ambient-color $inset;
}