$invisible: rgba(255,255,255,0.000302);

$theme-one: #000000;			//black
$theme-two: #f44034;			//red
$theme-three: #ffc348; 		//yellow

$copy: black;
$copy-light: lighten($copy, 60);

@mixin gradient-lighter($color) {
	background: linear-gradient(to top, $color, lighten($color, 8));
	background-color: $color;
}
@mixin gradient-darker($color) {
	$color2: darken($color, 5);
	background: linear-gradient(to bottom, $color, saturate($color2, 10));
	background-color: $color;
}

@function deepen($color, $amount) {
	$color: darken( $neutral, 3 * $amount);
	$color: desaturate( $color, 3 * $amount);
	$color: adjust-hue( $color, -1deg * $amount);
	@return $color;
}
$neutral: #fef9f0;
$neutral-dark: deepen($neutral, 1);
$neutral-darker: deepen($neutral, 6);
$neutral-darkest: deepen($neutral, 12);

$neutral-light: lighten($neutral, 3);