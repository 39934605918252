header {
	@include clearfix;

	@include gradient-darker($neutral);

	> .wrapper {

		> nav {
			position: absolute;
			top: 50%;
			left: 50%;

			text-align: center;

			transform: translate(-50%, -50%);

			> img {
				width: fibo(6);
				max-width: 100%;
				max-height: 15rem;
				@include breakpoint($md) {max-height: none;}
			}

			> #contents-list {
				margin-top: 1rem;
			}

			> .social-media-links {
				margin-top: 2rem;
				text-align: center;

				> li {
					display: inline-block;
					margin: 0 0.5rem;

					> a {
						opacity: 0.2;

						> img {
							height: 1.618rem;
							width: auto;
							max-width: none;
						}

						&:hover {
							opacity: 1;
						}
					}
				}
			}
		}
	}

	ul {
		padding-left: 0;
		border-left: none;
		text-indent: none;
	}
	li {
		margin-top: 0;
	}
	li:before {
		display: none;
	}
}

@include breakpoint($lg) {
	header {
		position: relative;
		float: left;
		width: $header-lg-width;

		background: none;

		 > .wrapper {
		 	top: 10%;
			position: fixed;
			width: $header-lg-width;
			will-change: transform;
			
			backface-visibility: hidden;

			> nav {
				width: 100%;
				left: 0;
				padding-left: $padding-small;
				padding-right: $padding-small;

				transform: none;
			}
		}
	}
}

@include breakpoint($xl) {
	header {
		width: $header-xl-width;

		 > .wrapper {
			width: $header-xl-width;

			> nav {
				padding-left: $padding-medium;
				padding-right: $padding-medium;
			}
		}
	}
}