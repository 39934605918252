$navbar-height: 3.5rem;
$indicator-width: 0.75rem;
$indicator-margin: 0.25rem;

.navbar {
	height: $navbar-height;
	width: 100%;

	background-color: $neutral;
	// @include material-shadow(2, black);
}

.navbar-bottom {
	position: fixed;
	bottom: 0;
	left: 0;

	text-align: center;

	background-color: $theme-two;

	transform: translate3d(0, $navbar-height, 0);
	transition: transform $transition-hover;
}

.navbar-top {
	position: fixed;
	top: 0;
	left: 0;

	text-align: center;

	background-color: $theme-two;

	transform: translate3d(0, (-1 * $navbar-height), 0);
	transition: transform $transition-hover;
}

.event-nav-middle {
	display: block;
	text-align: center;
	width: 100%;

	.label {
		color: $neutral;
		font-size: fibo(0.25);
	}

	.event-nav-indicator-wrapper {
		position: relative;
		display: inline-block;

		.indicator-empty, .indicator-active {
			float: left;
			height: $indicator-width;
			width: $indicator-width;
			margin: 0 $indicator-margin;

			border-radius: 50%;
		}

		.indicator-empty {
			border: solid 2px $neutral;
		}

		.indicator-active {
			position: absolute;
			background: $neutral;

			transform: translate3d(0, 0, 0);
			transition: transform $transition-hover;
		}
	}
}