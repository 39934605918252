img {
	width: 100%;
	max-width: 100%;
	height: auto;
}

.sponsor-logo {
	float: left;
	height: 5rem;
	width: auto;
	margin-right: 1rem;
	margin-top: 1rem;
}