$column-gutter: 2rem;

$contained-width: fibo(8.25);
$contained-width-wide: fibo(8.5);
$contained-width-x-wide: fibo(9.25);
$contained-width-narrow: fibo(7.5);

$padding-nano: 0.25rem;
$padding-micro: 0.5rem;
$padding-tiny: 0.75rem;
$padding-small: 1.25rem;
$padding-medium: 2.5rem;
$padding-large: 5rem;
$padding-x-large: 9rem;

$rule-color: transparentize(black, 0.9);
$rule-stack: solid $rule-color 1px;
$rule-stack-medium: solid $rule-color 2px;
$rule-stack-large: solid $rule-color 3px;

$border-radius-small: 5px;
$border-radius-medium: 7px;

$curve-hover: cubic-bezier(0.36, 0.11, 0.25, 1);
$curve-slide: cubic-bezier(0.18, 0.85, 0.52, 1);

$time-reflow: .15s;
$time-hover: .28s;
$time-fade: 0.6s;
$time-slide: .8s;

$transition-reflow: $time-reflow $curve-hover;
$transition-hover: $time-hover $curve-hover;
$transition-fade: $time-fade $curve-hover;
$transition-slide: $time-slide $curve-slide;

$icon-size-small: fibo(0.5);
$icon-size: fibo(1.5);

// layers
$layer-over-top: 4;

$layer-body-top: 2;
$layer-body-bottom: 1;

$layer-surface-top: -2;
$layer-surface-bottom: -4;

$event-layer: 10;
$event-image-ratio: percentage(4 / 7);
$event-header-details-width: 7rem;

$infinitesimal: 0.0000001;

$source-serif-stack:
	"Source Serif Pro"
	, "Georgia"
	, serif;
$helvetica-stack: 
	"Helvetica Neue"
	, "Helvetica"
	, sans-serif;
$source-sans-stack: 
	"Source Sans Pro"
	, $helvetica-stack;

$margin-header: 1.5rem;
$header-lg-width: fibo(5.5);
$header-xl-width: fibo(6);
$header-lg-margin-top: $padding-medium;

$xs: 19px;
$sm: 28rem;
$md: 48rem;
$lg: 70rem;
$xl: 80rem;

