.carousel-wrapper {
	margin-bottom: $padding-medium;
	> .padding-medium {
		padding: 0 !important;
	}
}
@include breakpoint($md) {
	.carousel-wrapper {
		padding-left: $padding-large;
		padding-right: $padding-large;
		max-width: $contained-width;

		> .wrapper-inner {
			border-radius: 2px;
			padding: 10px;
			@include material-shadow(2, $neutral-darkest);
		}
	}
}



.carousel {
	position: relative;
	background-color: white;

	overflow: hidden;

	> .images {
		height: 0;
		padding-bottom: 66.6666666%;
		
		transition: transform $transition-reflow;

		> img {
			display: block;
			position: absolute;
			height: 100%;
			width: auto !important;

			backface-visibility: hidden;
		}
	}
}

.carousel > .direction-control-left,
.carousel > .direction-control-right
{
	position: absolute;
	width: 50%;
	height: 100%;
	top: 0;

	&:after {
		display: inline-block;
		position: absolute;
		width: 3rem; 
		height: 100%;
		top: 0;

		text-align: center;
		vertical-align: middle;
		font-size: fibo(2);
		color: white;

		background-color: $theme-two;
		
		//forces compositing.
		transform: translateZ(0);
		opacity: 0.0000001;

		content: '';
		transition: opacity $transition-reflow;
	}

	&:hover {
		&:after {
			opacity: 0.5;
		}
	}
}

.carousel > .direction-control-left {
	left: 0;
	&:after {
		left: 0;
	}
}
.carousel > .direction-control-right {
	right: 0;
	&:after {
		right: 0;
	}
}