.cta {
	display: inline-block;
	cursor: pointer;
	position: relative;
	width: 100%;
	padding: 0.25rem 0rem;
	margin-bottom: 0.5rem;
	
	color: white !important;
	text-align: center;
	text-transform: uppercase;
	font-family: $source-sans-stack;
	font-size: fibo(-.5);
	font-weight: 600;
	letter-spacing: 0.1rem;

	background-color: $theme-two;
	border-radius: $border-radius-medium;

	overflow: hidden;
	transition: background-color $transition-reflow;

	&:hover {
		background-color: $theme-three;
	}
}

.cta.light {
	color: $theme-two !important;
	background-color: transparent;
	border: $rule-stack-medium;
	border-color: $theme-two;

	&:hover {
		background-color: rgba($theme-two, 0.2);
	}
}


.cta.theme-one {
	background-color: $theme-one;

	&:hover {
		background-color: $theme-two;
	}
}

.cta.on-red {
	background-color: $theme-one;

	&:hover {
		background-color: $theme-three;
	}
}