.leadership-list {
	@include clearfix;

	> .leadership {
		float: left;
		width: 100%;
		margin-bottom: $padding-tiny;

		> .inner {
			background-color: white;

			> .details {
				> * {
					margin-bottom: 0;
				}

				padding: 0 $padding-micro;
			}
		}
	}
}

@include breakpoint($md) {
	.leadership-list {
		margin: 0 -$padding-micro;
		> .leadership {
			width: 50%;
			padding: 0 $padding-micro;
		}
	}
}