html {
}

body {
	font-family: $source-serif-stack;
	font-size: 1rem !important;
	font-weight: 400;
	line-height: 1.618;
}

p, small {
	hyphens: auto;
	margin-bottom: 1rem;

	&:last-child {
		margin-bottom: 0;
	}
}
em {
	font-style: italic;
}
a {
	font-family: $source-sans-stack;
}

h1, h2, h3, h4, h5, h6 {
	font-family: $source-sans-stack;
	margin-bottom: 0.5rem;
}

h2 {
	font-size: fibo(1);
	font-weight: 600;
}
h3 {
	font-size: fibo(0.75);
	font-weight: 600;
}
h4 {
	@extend .small-caps;
	display: inline-block;
	
	font-size: fibo(1.5);
	font-weight: 600; 

	border-bottom: $rule-stack-medium;
	border-color: $theme-two;
}
h5 {

}
h6 {

}

small {
	display: block;
}


.small-caps {
	font-family: $source-sans-stack;

	font-size: fibo(-.5);
	text-transform: uppercase;
	font-weight: 600;
	letter-spacing: 0.1rem;

	text-indent: 0;

	&.wide {
		letter-spacing: 0.2rem;
	}
}

.centered {
	text-align: center;
}

::selection {
	background-color: transparentize($theme-three, 0.2);
}

time {
	display: inline;
	font-family: $source-sans-stack;
	@include clearfix;

	.day {
		font-size: fibo(1.5);
		line-height: 1.15;
	}
}

.fish-fry-date {
	display: block;
}

@include breakpoint($lg) {
	// html {
	// 	font-size: 17px;
	// }
}

@media 
(-webkit-min-device-pixel-ratio: 2), 
(min-resolution: 192dpi) { 
	html {
		font-size: 15px;
	}
}