html {
	text-align: center;
	width: 100%;
}
body {
	display: inline-block;
	background-color: $neutral;

	text-align: left;
}
main {
	background-color: white;
	float: right;

}
footer {
	background-color: $theme-one;
	color: white;
}


section,
footer {
	@include clearfix;
	padding-bottom: $padding-large;
	padding-top: $padding-large;

	&:first-child {
		padding-top: 0;
	}

	//backgrounds
	&.black{ 
		@include gradient-lighter($theme-one);
		color: white;
		p {letter-spacing: 0.5px;}
	}
	&.red{ 
		@include gradient-lighter($theme-two);

		a:hover{ color: white !important; }
		ol {
			border-color: white;
		}
		h4 {
			border-color: transparentize($theme-one, 0.8);
		}
		li:before{
			color: white;
		}
	}
	&.yellow{ @include gradient-lighter($theme-three); }
	
	> section {
		background-color: none;
		padding-bottom: 0;
		padding-top: 0;
	}
}



.centered {
	text-align: center;
}
.justified {
	text-align: justify;
	text-align-last: justify;

	&:before {
		content: '';
		display: block;
		width: 100%;
		margin-bottom: -1.618rem;
	}
	&:after {
		content: '';
		display: inline-block;
		width: 100%;
	}

	> * {
		display: inline-block;
		position: relative;
		top: 1.618rem;
		line-height: 0;
	}
}


.padding-small {
	padding: 0 $padding-small;
}
.padding-medium {
	padding: 0 $padding-medium;
}


.markdown {
	> img {
		@extend .card-bordered-middle;

		display: block;
		width: 80%;
		margin: 2rem auto;

		background-color: white;
	}
}


//table
table {
	width: 100%;
	font-family: $source-sans-stack;
}
tr {
	border-bottom: $rule-stack-medium !important;
}


ul, ol {
	padding-left: 1.5rem;
	text-indent: -.5rem;
	margin-bottom: $padding-small;
	margin-top: $padding-small;
}

p ~ ul,
p ~ ol,
{
	padding: $padding-nano $padding-medium;
	// border-left: $rule-stack-large;
	// border-color: $theme-two;
}

li:before {
	display: inline-block;
	margin-right: 0.5rem;

	color: $theme-two;

	content: "•";
}
li + li {
	margin-top: $padding-nano;
}


hr {
	border: $rule-stack;
	max-width: 6rem;
}

.support-item {
	text-align: center;
	margin: $padding-small 0;

	p {
		text-align: left;
	}

	:last-child {
		margin: 0;
	}
}


.google-map {
	background-color: white;
	margin-top: $padding-medium;

	> .map-element {
		height: fibo(7);
		margin-bottom: 0.5rem;
	}
}

.saengerfest-logo {
	display: inline-block !important;
	width: auto !important;
	padding: 0 !important;
	margin: -0.5rem 0 1rem -0.5rem !important;
	height: fibo(3.5);

	background-color: unset !important;
	box-shadow: none !important;
}
.edelweiss-logo {
	max-width: 30rem;
}

@include breakpoint($md) 
{
	.padding-medium {
		padding-left: $padding-large !important;
		padding-right: $padding-large !important;
		max-width: $contained-width;
	}
}

@include breakpoint($lg) 
{
	main {
		@include material-shadow(7, $neutral-darkest);
		float: left;
		max-width: $contained-width;
	};
}

@include breakpoint($xl) 
{
	main {
		max-width: $contained-width-wide;
	}
}