$column-gutter: $padding-medium;
$nColumns: 12;

.row {
	@include clearfix;
}
[class*='col-'] {
	position: relative;
	float: left;
	width: 100%;
	height: 100%;
	margin-bottom: 1rem;

}

[class*='col-xs'] {
	&:not(:last-of-type) {
		padding-right: ($column-gutter / 2);
	}
	&:not(:first-of-type) {
		padding-left: ($column-gutter / 2);
	}
	&:only-child {
		padding-right: ($column-gutter / 2);
	}
}

//always
@for $i from 1 through $nColumns {
	.col-xs-#{$i} {
		width: percentage($i / $nColumns);
	}
}

@each $bp, $width in (sm: $sm, md: $md, xl: $xl) {
	@include breakpoint($width) {
		@for $i from 1 through $nColumns {
			[class*='col-#{$bp}'] {
				&:not(:last-of-type) {
					padding-right: ($column-gutter / 2);
				}
				&:not(:first-of-type) {
					padding-left: ($column-gutter / 2);
				}
				&:only-child {
					padding-right: ($column-gutter / 2);
				}
			}
			.col-#{$bp}-#{$i} {
				width: percentage($i / $nColumns);
			}
		}
	}
}