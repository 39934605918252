// use only for blanket fixes and hacky stuff.

// use if we notice significant flickering
// *,
// *::before,
// *::after {
// 	backface-visibility: hidden;
// 	-webkit-backface-visibility: hidden;
// }

body {
	-webkit-font-smoothing: subpixel-antialiased;
	font-smoothing: subpixel-antialiased;
}

html {
	// prevents scaling on orientationchange
	-webkit-text-size-adjust: 100%;
}

@mixin clearfix {
  &:after {
    content:"";
    display:table;
    clear:both;
  }
}