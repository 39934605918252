.nav-link {
	display: block;
	width: 100%;
}

a:hover {
	color: $theme-two;
}

.external-links {
	a {
		display: block;
	}
}

