.shield {
	max-width: fibo(5);
}


.shields-wrapper {
	position: relative;
	overflow: hidden;
	height: fibo(6);

	> .shield {
		position: absolute;
		left: 0;
		bottom: 0;

		transition: transform $transition-slide;

		img {
			backface-visibility: hidden;
		}
	}
}