%card {
	border-radius: 2px;
	overflow: hidden;
}

.card-low {
	@extend %card;
	@include material-shadow(1, $neutral-darkest);
}
.card-middle {
	@extend %card;
	@include material-shadow(2, $neutral-darkest);
}
.card-high {
	@extend %card;
	@include material-shadow(5, $neutral-darkest);
}

.card-bordered-low {
	@extend .card-low;
	padding: 10px;
}
.card-bordered-middle {
	@extend .card-middle;
	padding: 10px;
}
.card-bordered-high {
	@extend .card-high;
	padding: 10px;
}