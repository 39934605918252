.event-list-inner {
	margin-top: $padding-medium;

	text-align: center;

	> * {
		text-align: left;
	}
}

.event-year-divider {
	display: inline-block;
	margin-bottom: $padding-micro;
	font-family: $source-sans-stack;

	&::before,
	&::after {
		content: '';
		display: inline-block;
		vertical-align: middle;
		width: fibo(3);
		border-bottom: $rule-stack;
		margin: 0 1rem;
	}
}

.event-directions {
	padding: 0 $padding-small;
}

.event-row {
	padding: 0 $padding-small;
}

// universal stuff
.event {
	width: 100%;
	padding: 0 $padding-tiny;

	// perspective: 800;
	backface-visibility: hidden;

	-webkit-tap-highlight-color: rgba(0,0,0,0);

	.details {
		font-family: $source-sans-stack;
		p {
			margin-top: 0;
		}

		.day {
			font-size: fibo(1.5);
			line-height: 1.15;
		}
	}

	.event-inner {
		background-color: white;
	}
}

.event.expanded,
.event.featured {
	padding: $padding-tiny;

	.event-collapsed {
		display: none;
	}
	.event-expanded {
		display: block;
	}
}

.event.featured {
	.event-expanded {
		opacity: 1;
		transform: matrix3d(1,0,0,0,0,1,0,0,0,0,1,0,0,0,0,1);
	}
}

//------------------------------------------------------ 
// Breakpoint: XS
//------------------------------------------------------ 
.event.featured {

	> .event-inner > .event-expanded {

		&::after {
			display: block;
			position: absolute;
			top: $padding-small;
			padding: $padding-micro $padding-small;
			
			font-family: $source-sans-stack;

			background-color: $theme-three;

			content: 'Featured Event';
		}
	}
}

.event-collapsed {
	@include clearfix;
	position: relative;

	width: 100%;
	padding: $padding-nano $padding-tiny;
	margin-bottom: $padding-tiny;

	border-bottom: $rule-stack-medium;
	border-color: $theme-two;
	cursor: pointer;

	time { 
		float: left;
		width: 20%;
	}

	h3 { 
		float: right;
		margin-bottom: 0;
		width: 80%;
		text-align: right;
	}
}
//states
.event-collapsed:hover {
	color: $theme-two;
}

.event-expanded {
	display: none; 	// only shown when event-wrapper has expanded class
	padding-bottom: $padding-small;
	margin-bottom: $padding-tiny;

	opacity: 0; // opacity: 1; set in event-list.js due to timing issue.
	transform-origin: top;
	transform: matrix3d(0.5,0,0.00,0,0.00,0.47,0.34,0,0,-0.34,0.94,0,0,-40,0,1);

	transition
		: opacity $transition-reflow
		, transform $transition-reflow;

	> .event-images {
		position: relative;
		float: left;
		width: 100%;
		height: 0;
		padding-bottom: $event-image-ratio;

		overflow: hidden;
		background-color: $neutral-dark;

		> .event-image {
			position: absolute;
			width: 100%; 
			height: auto;
			left: 0;
		}

		> .default-image {
			position: absolute;
			height: 100%;
			width: auto;
		}
	}
	
	.event-details {
		padding-left: $padding-small;
		padding-right: $padding-small;

		background: linear-gradient( to top
			, transparentize($neutral-darkest, 0.75)
			, transparentize($neutral-darkest, 1) 0.5rem);

		> * {
			margin-right: $padding-small;
			border-bottom: $rule-stack-large;
			border-color: $theme-two;
			margin-bottom: 0;
		}

		* {
			display: inline-block;
			vertical-align: bottom;
		}
	}

	.event-header {
		display: inline-block;
		width: 100%;
		padding: $padding-micro $padding-small 0 $padding-small;

		font-family: $source-sans-stack;

		.title {
			float: left;
			margin-bottom: 0;
		}

		.admission-cost {
			height: 100%;
			margin-top: 0.6rem;
			float: right;
		}
	}

	.event-body {
		padding: 0 $padding-small 0 $padding-small;
		overflow: hidden;
		margin-bottom: 1rem;
	}
}

//------------------------------------------------------ HIDING: XS
.event-expanded {
	.address {
		span {display: none;}
		span.name {display: inline}
	}
	.event-header {
		.admission-cost {
			display: none;
		}
	}
}
// End of Breakpoint:XS



//------------------------------------------------------ 
// Breakpoint: SM
//------------------------------------------------------
// End of Breakpoint: SM



//------------------------------------------------------ 
// Breakpoint: MD
//------------------------------------------------------
//// just trying some things out
@include breakpoint($md) 
{
	.address {
		> span {display: inline !important;}
	}

	.event-list-inner {
		padding-left: $padding-large;
		padding-right: $padding-large;
	}

	.event.featured {
		padding: 0;
		
		> .event-inner > .event-expanded {
			box-shadow: none;
			
			> .event-details
			, > .event-header
			, > .event-body
			, > .event-directions {
				padding-left: $padding-large;
				padding-right: $padding-large;
			}
		}
	}
}
// End of Breakpoint: MD



//------------------------------------------------------ 
// Breakpoint: LG
//------------------------------------------------------
@include breakpoint($lg) 
{
	#upcoming-events-label {
		display: none;
	}
}
// End of Breakpoint: LG



//------------------------------------------------------ 
// Breakpoint: XL
//------------------------------------------------------
@include breakpoint($xl) 
{
	.event-list-inner {
		padding-left: $padding-large;
		padding-right: $padding-large;
	}

	.event.featured 
	> .event-inner 
	> .event-expanded 
	{
		> .event-details
		, > .event-header
		, > .event-body
		, > .event-directions {
			padding-left: $padding-large;
			padding-right: $padding-large;
		}
	}
}
// End of Breakpoint: XL